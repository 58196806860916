.container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.panel {
  padding: 1rem;
  margin: 0.5rem;
}

.selectionPanel {
  width: 80%;
}

.previewPanel {
  width: 20%;
}

.formControl {
  margin-top: 1rem;
}

.messageItem {
  padding: 5px;
  cursor: pointer;
}
