/*************************************/
/*            UTILITIES              */
/*************************************/

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

/*************************************/
/*               CUSTOM              */
/*************************************/
