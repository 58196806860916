.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 3rem;
}

.content {
  width: 100%;
  margin-top: 5rem;
}
