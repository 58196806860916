.loginBtn {
  background-color: var(--sds-c-button-brand-color-background, #0176d3);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.loginBtn:hover {
  background-color: var(--sds-c-button-brand-color-background-hover, #014486);
  color: white;
  text-decoration: none;
}
